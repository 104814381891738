.reminder-form {
  max-width: 300px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid var(--button-background);
  border-radius: 5px;
  background-color: var(--background-color);
  color: var(--text-color);
}

.reminder-form h2 {
  margin-bottom: 15px;
  font-size: 18px;
  color: var(--text-color);
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: var(--text-color);
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--button-background);
  border-radius: 4px;
  background-color: var(--background-color);
  color: var(--text-color);
}

.button-group {
  display: flex;
  justify-content: space-between;
}

.button-group button {
  padding: 8px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Set Reminder button */
.button-group button:nth-child(1) {
  background-color: var(--set-button-background);
  color: var(--set-button-text-color);
}

/* Cancel button */
.button-group button:nth-child(2) {
  background-color: var(--cancel-button-background);
  color: var(--cancel-button-text-color);
}

.button-group button:hover {
  opacity: 0.8;
}
