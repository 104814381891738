/* ToggleSwitch.css */

/* Switch button styles */
.switch {
  position: absolute;
  top: 10px; /* Adjust as needed */
  left: 10px; /* Adjust as needed */
  display: inline-block;
  width: 40px;
  height: 40px; /* Match height with other buttons */
  vertical-align: middle; /* Align vertically with other elements */
  z-index: 1000; /* Ensure it stays on top */
 
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--toggle-background);
  transition: .4s;
  border-radius: 12px; /* Make it similar to the save button */
   border: 0.2px solid var(--toggle-background); /* Add border to match the button */
}

.slider:before {
  position: absolute;
  content: "Off";
  height: 24px;
  width: 24px;
  left: 8px;
  bottom: 8px;

  transition: .4s;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: var(--text-color);
  background: var(--toggle-background);
}
input:checked + .slider {
  background: var(--toggle-active);
}

input:checked + .slider:before {
  transform: translateX(0);
  content: "On";
  background: var(--toggle-active);

}




/* Positioning and styling for the info message */
.info-message {
  position: absolute;
  top: 50px; /* Adjust based on your layout */
  left: 10px; /* Align with the toggle switch */
  background-color: rgba(0, 0, 0, 0.8); /* Dark background for contrast */
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1200;
}



.info-message::after {
  content: '';
  position: absolute;
  top: -6px; /* Adjust to move it closer to the tooltip */
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent #333 transparent;
  z-index: 1100;
}

/* Fade-in and fade-out animations */
@keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeout {
  from { opacity: 1; }
  to { opacity: 0; }
}
