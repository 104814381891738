.theme-selector {
  position: relative;
  display: inline-block;
}

.theme-icon {
  font-size: 24px;
  cursor: pointer;
}

.theme-dropdown {
  background: var(--button-background); /* Use button background variable */
  border: 1px solid var(--button-text-color); /* Use button text color for border */
  border-radius: 5px;
  box-shadow: 0 4px 6px #0003;
  left: 50px;
  position: absolute;
  top: 100%;
  transform: translateX(-45%);
  width: 150px;
  padding: 10px;
  z-index: 1000;
}

.theme-option {
  padding: 10px;
  cursor: pointer;
  color: var(--text-color); /* Use text color variable */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.theme-option:hover {
  background: var(--toggle-active); /* Highlight on hover using toggle active color */
  color: var(--button-background); /* Invert colors on hover */
}

.theme-option.active {
  background: var(--toggle-active); /* Active theme option background */
  color: var(--button-background); /* Active theme option text color */
}

.react-tooltip {
  background-color: #333;
  color: #fff;
  padding: 8px 10px;
  border-radius: 5px;
  font-size: 12px;
  z-index: 1100;
}
