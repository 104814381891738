/* MyAccount.css */

.container {
    padding: 20px;
  }
  
  .account-header {
    display: flex;
    align-items: center;
  }
  
  .account-header h1 {
    margin-left: 10px; /* Adjust margin as needed */
  }
  
  .back-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 32px; /* Adjust size as needed */
    color: black; /* Adjust color as needed */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .back-button:hover {
    color: #555; /* Adjust hover color as needed */
  }
  