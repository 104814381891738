/* MyLists.css */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh; /* Use the full height of the viewport */
  background-color: var(--background-color);
  color: var(--text-color);
}

.lists-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px; /* Adjust padding as needed */
  color: var(--text-color);
}

.lists-header h1 {
  margin-left: 10px; /* Adjust margin as needed */
}

.back-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 32px; /* Adjust size as needed */
  color: var(--text-color); /* Adjust color based on theme */
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-button:hover {
  color: #555; /* Adjust hover color as needed */
}

/* Existing styles */
.saved-list {
  margin-bottom: 15px;
  border: 1px solid var(--button-background);
  border-radius: 8px;
  padding: 10px;
  background: var(--todo-row-background-1);

  
  width: 90%;
}

.list-toggle {
  background: var(--button-background);
  color: var(--button-text-color);
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button.list-toggle:hover {
  opacity: 0.5;
  
}

.todo-list {
  margin-top: 10px;
  padding: 10px;
  background: var(--todo-row-background-2);
  border-radius: 5px;
}

.todo-item {
  margin: 5px 0;
  word-wrap: break-word;
  color: var(--text-color);
}







.delete-list-button {
  margin-left: 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}



.delete-list-icon, .print-list-icon {
  cursor: pointer;
  font-size: 20px; /* Adjust the size as needed */
  margin-left: 10px; /* Space between the icon and other elements */
  
  color: var(--button-text-color);
}

.delete-list-icon:hover, .print-list-icon:hover {
  opacity: 0.5; /* Darker shade on hover */
}


.warning-icon-button {
  background: none;
  border: none;
  color: inherit;
  font-size: 1em; /* Increased font size for larger icon */
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-left: 10px; /* Maintain space between text and icon */
  padding: 0; /* Adjust padding as needed */
  vertical-align: middle; /* Aligns icon better with adjacent text */
}

.warning-icon-button:hover {
  color: #cc0000; /* Change color on hover to indicate interactiveness */
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050; 
  padding-top: 5%; /* Add spacing above the modal */
  padding-bottom: 5%; /* Add spacing below the modal */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%; /* Adjust based on preference */
  max-width: 500px;
  overflow-y: auto; /* Enable scrolling for overflow */
  position: relative; 
  width: 90%; /* Adjust width for smaller screens */
  max-width: 500px; /* Restrict width for larger screens */
  max-height: 80vh; 
}

.modal-content h2 {
  color: #333; /* Dark color for heading */
  margin-bottom: 10px;
}

.modal-content p, .modal-content ol {
  color: #666; /* Slightly lighter color for text */
  font-size: 1rem; /* Standard font size */
  line-height: 1.5; /* Good for readability */
}

.modal-content ol {
  padding-left: 20px; /* Proper indentation for lists */
}

.modal-button-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #ff0000;
  font-size: 1rem;
  cursor: pointer;
}

.modal-button-close:hover {
  color: #333; /* Darker color on hover for better interaction feedback */
}

/* Styles for Save and Share icons */
.save-list-icon, .share-list-icon {
  cursor: pointer;
    font-size: 20px; /* Adjust the size as needed */
  margin-left: 10px; /* Space between the icon and other elements */
}

.save-list-icon:hover, .share-list-icon:hover {
  opacity: 0.5; /* Darker shade on hover */
}
