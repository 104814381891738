.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .privacy-header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px; /* Adjust padding as needed */
  }
  
  .privacy-header h1 {
    margin-left: 10px; /* Adjust margin as needed */
  }

  .back-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 32px; /* Adjust size as needed */
    color: black; /* Adjust color as needed */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .back-button:hover {
    color: #555; /* Adjust hover color as needed */
  }
  
  .privacy-text {
    width: 80%;
  }
  
  .privacy-text p {
    font-size: 16px;
  line-height: 1.5;
  }
  
  .privacy-text ul {
    margin: 10px 0;
    padding-left: 20px;
  }
  
  .privacy-text ol {
    margin-top: 10px;
}
  
  
  .privacy-text li {
    margin-bottom: 10px;
  }
  