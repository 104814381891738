* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

body {
  background: var(--background-color);  /* Using CSS variable */
  color: var(--text-color);  /* Using CSS variable */
  
}
.todo-app {
  position: relative; /* Required for the settings icon */
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 520px;
  min-height: 600px;
  background: var(--background-color);  /* Using CSS variable */
  color: var(--text-color);
  text-align: center;
  margin: 128px auto;
  border-radius: 10px;
  padding-bottom: 32px;
  margin: 5px auto;
}

h1 {
  margin: 32px 0;
  color: var(--text-color);  /* Using CSS variable */
  font-size: 24px;
}

.complete {
  text-decoration: line-through;
  opacity: 0.4;
  transform: scale(1.1); /* Scale up slightly to indicate completion */
}


.complete {
  animation: bounce 0.5s ease;
}

@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

.todo-form {
  margin-bottom: 32px;
}

.todo-input {
  padding: 14px 32px 14px 16px;
  border-radius: 4px 0 0 4px;
  border: 2px solid var(--button-background);  /* Using CSS variable */

  outline: none;
  width: 320px;
  background: transparent;
  color: var(--text-color);  /* Using CSS variable */
}

.todo-input::placeholder {
  color: #e2e2e2;
}

.todo-button {
  padding: 16px;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  outline: none;
  
  background: var(--button-background);  /* Using CSS variable */
  color: var(--button-text-color);  /* Using CSS variable */
  text-transform: capitalize;
}

.todo-input.edit {
  border: 2px solid #d6d6d6;
}

.todo-button.edit {
  background: rgb(125, 124, 124);
  padding: 16px 22px;
}

.todo-container {
  display: flex;
  flex-direction: row;
  position: relative;
}

.todo-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4px auto;
  color: #fff;
  background: rgb(16, 34, 34);
  color: var(--text-color);
  background: var(--todo-row-background-1); /* Default row background */

  padding: 16px;
  border-radius: 5px;
  width: 90%;
}
.todo-input .text {
  word-wrap: break-word; /* Ensures text wraps */
}

.todo-app div:nth-child(6n + 1) .todo-row {
  background: var(--todo-row-background-1);
}

.todo-app div:nth-child(6n + 2) .todo-row {
  background: var(--todo-row-background-2);
}

.todo-app div:nth-child(6n + 3) .todo-row {
  background: var(--todo-row-background-3);
}
.todo-app div:nth-child(6n + 4) .todo-row {
  background: var(--todo-row-background-4);
}
.todo-app div:nth-child(6n + 5) .todo-row {
  background: var(--todo-row-background-5);
}
.todo-app div:nth-child(6n + 6) .todo-row {
  background: var(--todo-row-background-6);
}

.icons {
  display: flex;
  align-items: center;
  font-size: 22px;
  cursor: pointer;
}

.delete-icon, .edit-icon, .move-icon, .reminder-icon {
  margin-right: 5px;
  color: var(--todo-icon-color);
  opacity: 0.5;

}



/* Hover Effects */
.todo-button:hover,
.edit-icon:hover,
.delete-icon:hover,.menu-icon:hover,.move-icon:hover,.reminder-icon:hover {
  opacity: 0.8; /* Subtle opacity change on hover */
}

/* Focus Styles */
.todo-button:focus,
.todo-input:focus,
.edit-icon:focus,
.delete-icon:focus {
  outline: 2px solid #ffffff; /* Highlight focus for accessibility */
}

.todo-row {
  
  display: flex; /* Set the todo row as a flex container */
  flex-wrap: wrap; /* Allow the contents of the todo row to wrap */
  align-items: center; /* Optional: Align items vertically */
  padding: 10px; /* Ensure there's padding for wrapped text */
}


.todo-text {
  white-space: normal;
  word-wrap: break-word;
  width: 100%; /* Ensure it has a width to work with */
}

.header {
  display: flex;
  align-items: center;
  padding: 20px 20px;
  position: relative;
  background-color: var(--background-color);
  color: var(--text-color);
}

.header-right {
  display: flex;
  align-items: center;
}

.icon-group {
  display: flex;
  align-items: center;
  gap: 10px; /* Add spacing between the theme and menu icons */
}

.theme-icon {
  font-size: 24px; /* Adjust the size */
  cursor: pointer;
}

.menu-icon {
  cursor: pointer;
  position: relative;
}

.menu-icon {
  position: absolute; /* Position relative to the nearest positioned ancestor */
  top: 10px; /* Adjust as needed for spacing from the top */
  right: 10px; /* Adjust as needed for spacing from the right */
  cursor: pointer;
  z-index: 100; /* Adjust as needed based on your layout */
  background: transparent; /* Optional: set a background color */
  border:0.5px darkgray;
  border-radius: 12px; /* Make corners rounded */
  padding: 8px; /* Add padding to ensure the background color is visible */

}

.menu-icon svg {
  width: 32px;   /* Set width of the icon */
  height: 32px;  /* Set height of the icon */
  color: var(--text-color);}




.dropdown-menu {
  display: none; /* Hide the menu initially */
  position: absolute;
  right: 0;
  top: 100%; /* Position it right below the settings icon */
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Optional: adds a shadow for depth */
  border-radius: 4px; /* Optional: rounded corners */
  z-index: 100;
  width: 200px; /* Adjust as needed */
  background: var(--background-color);
  color: var(--text-color);
}


.menu-icon:hover .dropdown-menu {
  display: block; /* Show the menu on hover */
}
.dropdown-menu a:hover {
  opacity: 0.9; /* Subtle opacity change on hover */
  background-color: var(--toggle-active);
}

.dropdown-menu a {
  color: var(--text-color);
  background-color: var(--button-background);
  padding: 10px 15px;
  text-decoration: none;
  display: block;
  
}





.save-todo-list-button {
  background: transparent; /* Set the background color to transparent */
  
  background-color: var(--button-background);
  color: var(--button-text-color);
  font-size: 14px; /* Adjust font size */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 120px !important; /* Forces this width, overriding other rules */
  width: auto; /* This will prevent inheriting width from parent */
  margin: 0 auto 20px; /* Center the button and add margin at the bottom */
 
}

.save-todo-list-button:hover {
  background-color: var(--button-text-color);
  color: var(--button-background);}





/* Mobile Phones: max-width of 767px */
@media only screen and (max-width: 767px) {
  .todo-app {
      width: 90%; /* Increase width for smaller screens */
      margin: auto; /* Adjust margin */
      padding-bottom: 20px; /* Adjust padding */
  }

  .todo-input, .todo-button {
      width: calc(100% - 40px); /* Adjust width to fit the container */
      margin: 10px; /* Adjust margin for spacing */
  }

  h1 {
      font-size: 20px; /* Adjust font size for smaller screens */
      margin: 20px 0; /* Adjust margin */
  }

  .todo-row {
      padding: 10px; /* Adjust padding for todo items */
      font-size: 14px; /* Adjust font size if necessary */
      transition: opacity 0.5s ease, transform 0.5s ease; /* Add transition */
  }

  /* Adjust other elements as needed */
}



.buttons-container {
  display: block; /* Change to inline-flex to restrict width */
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}



.dice-button, .save-todo-list-button {
  
  font-size: 14px; /* Smaller font size */
  border: 0.5px solid darkgrey; /* White border */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer;
  background-color: var(--button-background);
  color: var(--button-text-color);
  background: transparent;
    width: 120px; /* Adjust as needed, or set a specific width */
    min-width: 100px; /* Example minimum width */
    max-width: 120px; /* Example maximum width */
    
}
.dice-button svg {
  font-size: 24px; /* Or any other size */
}
.save-todo-list-button svg {
  font-size: 24px; /* Or any other size you prefer */
}



.dice-button:hover, .save-todo-list-button:hover {
  background-color: var(--button-text-color);
  color: var(--button-background);}

/* Adjustments for Smaller Screens */
@media only screen and (max-width: 767px) {
  .dice-button, .save-todo-list-button, .slider {
    padding: 5px 10px;
    font-size: 14px;
  }
}





.header-left {
  display: flex;
  align-items: center;
  gap: 50px; /* Space between ToggleSwitch and UndoRedoButtons */
}
.theme-icon {
  cursor: pointer;
  font-size: 28px;
  padding: 0px 35px;}

/* Theme.css */

/* Base styles for dark theme 
.theme-dark {
  background-color: #282c34; 
  color: #f8f8f2;  
}
*/
/* Specific component overrides for dark theme 
.theme-dark .header {
  background: linear-gradient(135deg, #4b6cb7 0%, #182848 100%);
}

.theme-dark .theme-selector {
  background: linear-gradient(to right, #ffecd2 0%, #fcb69f 100%);
  color: #333;
  border: 1px solid #f8f8f2;
}

.theme-dark .theme-options div {
  background: linear-gradient(to right, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%);
  color: #282c34;
  border: 1px solid #f8f8f2;
}

 Add more specific styles for other elements in dark theme */
/* Define the glowing animation */

@keyframes glow {
  0% {
    box-shadow: 0 0 5px var(--toggle-active), 0 0 10px var(--toggle-active), 
                0 0 15px var(--toggle-active), 0 0 20px var(--toggle-active), 
                0 0 25px rgba(253, 255, 194, 0.7), 0 0 30px rgba(253, 255, 194, 0.7), 
                0 0 35px rgba(253, 255, 194, 0.7);
  }
  50% {
    box-shadow: 0 0 10px var(--toggle-active), 0 0 15px var(--toggle-active), 
                0 0 20px rgba(253, 255, 194, 0.8), 0 0 25px rgba(253, 255, 194, 0.8), 
                0 0 30px var(--toggle-active), 0 0 35px rgba(253, 255, 194, 0.8), 
                0 0 40px rgba(253, 255, 194, 0.8);
  }
  100% {
    box-shadow: 0 0 5px var(--toggle-active), 0 0 10px var(--toggle-active), 
                0 0 15px var(--toggle-active), 0 0 20px rgba(253, 255, 194, 0.5), 
                0 0 25px rgba(253, 255, 194, 0.5), 0 0 30px rgba(253, 255, 194, 0.5), 
                0 0 35px rgba(253, 255, 194, 0.5);
  }
}





/* Apply the animation to the first to-do item */
.first-todo .todo-row {
  animation: glow 2s infinite alternate;
}

.popup {
  background:var(--todo-row-background-2);
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust position to truly center based on its own size */
  color:var(--text-color);
  padding: 10px;
  
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1100;
  width: auto; /* or max-width if necessary */
  text-align: center; /* Ensures text is centered if it wraps */
 
}
.cookie-container {
  background-color: white !important; /* Set the background to white */
  color: black !important; /* Set the text color to black */
  font-size: 16px; /* Optional: Adjust font size */
  padding: 15px; /* Optional: Add padding for better appearance */
  border-radius: 8px; /* Optional: Add rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Add subtle shadow */
}

.cookie-button {
  background-color: white !important; /* Set the button background to white */
  color: black !important; /* Set the button text color to black */
  border: 1px solid black; /* Optional: Add a black border */
  border-radius: 5px; /* Optional: Add rounded corners */
  padding: 10px 20px; /* Optional: Adjust padding */
  cursor: pointer; /* Add pointer cursor */
  transition: background-color 0.3s ease, color 0.3s ease; /* Optional: Add hover transition */
}

.cookie-button:hover {
  background-color: black !important; /* Change background to black on hover */
  color: white !important; /* Change text to white on hover */
}
.cookie-container a {
  color: blue; /* Adjust the color */
  text-decoration: underline; /* Add underline for link appearance */
}

.cookie-container a:hover {
  text-decoration: none; /* Optional: Remove underline on hover */
}



/* Hover Effects */
.todo-button:hover,
.edit-icon:hover,
.delete-icon:hover,
.move-icon:hover,
.reminder-icon:hover,
.dice-button:hover,
.save-todo-list-button:hover {
  opacity: 0.5; /* Subtle opacity change on hover */
  background-color: var(--button-text-color);
  color: var(--button-background);
}

@media (max-width: 768px) {
  .popup {
    top: 10%; 
    left: 50%; 
    transform: translate(-50%, 10%); 
    width: 80%; 
    padding: 15px; 
  }
}

@media (min-width: 769px) {
  .popup {
    top: 10%;  /* Adjusts to be closer to the top of the viewport on desktop */
    width: 50%;  /* Optionally adjust the width for desktop */
    padding: 20px;  /* More padding for a better desktop appearance */
    transform: translateX(-50%);  /* Centers the popup horizontally */
  }
}

