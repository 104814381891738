/* TermsAndConditions.css */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.terms-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px; /* Adjust padding as needed */
}

.terms-header h1 {
  margin-left: 10px; /* Adjust margin as needed */
}

.back-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 32px; /* Adjust size as needed */
  color: black; /* Adjust color as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-button:hover {
  color: #555; /* Adjust hover color as needed */
}

.terms-text {
  width: 80%;
}

.terms-text p {
  font-size: 16px;
  line-height: 1.5;
}

.terms-text ol {
  margin-top: 10px;
}

.terms-text li {
  margin-bottom: 10px;
}

.terms-text strong {
  font-weight: bold;
}

.terms-text p:last-child {
  margin-top: 20px;
}
